<template>

  <validation-observer ref="simpleRules">
    <b-form
      @submit.prevent="postEventData"
      @reset.prevent="OnReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Title *"
            label-for="title"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="title"
            >
              <b-form-input
                id="title"
                v-model="formData.title"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Description *"
            label-for="description"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="description"
            >
              <b-form-textarea
                id="description"
                v-model="formData.description"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Featured Image *"
            label-for="featured_image"
            label-cols-md="4"
          >
            <b-form-file
              id="featured_image"
              v-model="formData.featured_image"
              accept="image/*"
              type="file"
              @change="onFileChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Service By *"
            label-for="service_by"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="service_by"
            >
              <b-form-input
                id="service_by"
                v-model="formData.service_by"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Service By"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Start Date *"
            label-for="start_date"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="start_date"
            >
              <b-form-datepicker
                id="start_date"
                v-model="formData.start_date"
                placeholder="Start Date"
                local="en"
                initial-date=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="End Date *"
            label-for="end_date"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="end_date"
            >
              <b-form-datepicker
                id="end_date"
                v-model="formData.end_date"
                placeholder="End Date"
                local="en"
                initial-date=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Start Time *"
            label-for="start_time"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="start_time"
            >
              <b-form-timepicker
                v-model="formData.start_time"
                show-seconds
                locale="en"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="End Time *"
            label-for="end_time"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="end_time"
            >
              <b-form-timepicker
                v-model="formData.end_time"
                show-seconds
                locale="en"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="church"
          >
            <b-form-group
              label="Church *"
              label-cols-md="4"
              :state="errors.length > 0 ? false:null"
            >

              <v-select
                v-model="formData.church"
                placeholder="Please Select Church"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="churchData"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Location *"
            label-for="location"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="location"
            >
              <b-form-input
                id="location"
                v-model="formData.location"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Location"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Latitude"
            label-for="latitude"
            label-cols-md="4"
          >
            <b-form-input
              id="latitude"
              v-model="formData.latitude"
              type="text"
              placeholder="Latitude"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Longitude"
            label-for="longitude"
            label-cols-md="4"
          >
            <b-form-input
              id="longitude"
              v-model="formData.longitude"
              type="text"
              placeholder="Longitude"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Youtube Live Link"
            label-for="youtube_live_link"
            label-cols-md="4"
          >
            <b-form-input
              id="youtube_live_link"
              v-model="formData.youtube_live_link"
              type="text"
              placeholder="Youtube Live Link"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormFile, BFormTextarea, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref } from '@vue/composition-api'
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    // BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    // quillEditor,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      churchData: [],
      // categoryData: [],
      formData: {},
      required,
      userData: {},
    }
  },
  mounted() {
    this.getUser()
    this.getChurchData()
    // this.getCategoryData()
  },
  methods: {
    onFileChanged(event) {
      // eslint-disable-next-line prefer-destructuring
      this.formData.featured_image = event.target.files[0]
    },
    getChurchData() {
      axios
        .get('/churches')
        .then(res => {
          this.churchData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    // getCategoryData() {
    //   axios
    //     .get('/categories')
    //     .then(res => {
    //       this.categoryData = res.data.data
    //     })
    //   // eslint-disable-next-line no-console
    //     .catch(err => console.log(err))
    // },
    getUser() {
      const userLocalStorage = localStorage.getItem('user')
      const userDecoded = Buffer.from(userLocalStorage, 'base64').toString('utf-8')
      const userParsed = JSON.parse(userDecoded)
      this.userData = userParsed
    },
    postEventData() {
      this.$refs.simpleRules.validate().then(success => {
        const postData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.formData)) {
          postData.append(key, value)
        }

        // eslint-disable-next-line no-restricted-syntax
        // for (const pair of postData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }
        postData.append('created_by', this.userData.id === undefined ? '' : this.userData.id)
        postData.append('church_id', this.formData.church === undefined ? '' : this.formData.church.id)
        postData.delete('church')

        if (success) {
          this.$swal({
            title: 'Please Wait..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          }).then(
            axios.post('/events', postData).then(res => {
              this.form = {}
              // eslint-disable-next-line no-console
              console.log('res', res)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Your data has been saved.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'event-all' })
              })
            }),
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.2rem;
    margin-bottom: 2px;
    padding-bottom: 10px;
    height: 150px;
}

.ql-container{
  min-height: inherit;
}
</style>
